import { HrsIntegrationProviders } from 'api/generated/enums';
import { IPayrollReportDto } from 'api/generated/models';
import { SetPayrollSystemDeduction } from 'api/generated/permissions';
import useTeamProps from 'hooks/useTeamProps';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';

export const useHrsForPayrollReport = (
    activeReport: Pick<Partial<IPayrollReportDto>, 'isLocked' | 'month' | 'year'> | undefined
) => {
    const { hrsIntegrationProvider, isHrsIntegrationConnected } = useTeamProps();

    const { canSetPayrollSystemDeductions } = useSelector((state: AppStore) => ({
        canSetPayrollSystemDeductions:
            hasSomePermissions(state, SetPayrollSystemDeduction) && isHrsIntegrationConnected,
    }));

    const activeReportDate = moment()
        .month((activeReport?.month ?? NaN) - 1)
        .year(activeReport?.year ?? NaN);

    const isActiveReportDateSameOrInTheFuture =
        moment().isSameOrBefore(activeReportDate, 'month') &&
        moment().isSameOrBefore(activeReportDate, 'year');

    const isFinch = hrsIntegrationProvider === HrsIntegrationProviders.Finch;

    const canApplyDeductions =
        canSetPayrollSystemDeductions &&
        activeReport?.isLocked &&
        isActiveReportDateSameOrInTheFuture &&
        !isFinch;

    return { canApplyDeductions, isHrsIntegrationConnected };
};
