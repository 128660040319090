import { PlanTypeIds } from 'api/generated/enums';
import { IComparedSelectedPlan, ISelectedPlan } from 'api/generated/models';
import Button from 'components/Button';
import { MEDISHARE_GUIDELINES_URL } from 'constants/miscUrls';
import React from 'react';
import { hot } from 'react-hot-loader';
import { getPlanOrProgram, isMediShare } from 'utilities/selectedPlan';

const KeepOrChangeBenefitDetails = ({
    selectedPlan,
}: {
    selectedPlan: IComparedSelectedPlan | ISelectedPlan;
}) => {
    if ([PlanTypeIds.Marketplace, PlanTypeIds.MediShare, PlanTypeIds.OffExchange].includes(selectedPlan.planTypeId)) {
        const isMediSharePlanType = isMediShare(selectedPlan);
        const detailsText = getPlanOrProgram(isMediSharePlanType);
        let detailsLink = MEDISHARE_GUIDELINES_URL;
        if ([PlanTypeIds.Marketplace,PlanTypeIds.OffExchange].includes(selectedPlan.planTypeId)) {
            detailsLink = selectedPlan.benefitsLink ?? '';
        }
        return (
            <Button
                ButtonClassName="p-0"
                color="secondary"
                href={detailsLink}
                noPadding
                rel="noopener noreferrer"
                target="_blank"
                variant="text"
            >
                <small>{detailsText} Details</small>
            </Button>
        );
    }
    return <React.Fragment />;
};

export default hot(module)(KeepOrChangeBenefitDetails);
