import { DISCONNECT_FINCH_ACTION, disconnectFinch } from 'actions/finch/disconnectFinch';
import { FinchIntegrationStatuses } from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import { DisconnectFinch } from 'api/generated/permissions';
import ActionButtons from 'components/ActionButtons';
import ConfirmationModal from 'components/ConfirmationModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasSomePermissions } from 'selectors/index';

const IntegrationsActionButtons = ({ team }: { team: ITeam | undefined }) => {
    const { canDisconnectFinch, showActivity } = useSelector((state: AppStore) => ({
        canDisconnectFinch: hasSomePermissions(state, DisconnectFinch),
        showActivity: hasApiActivity(state, DISCONNECT_FINCH_ACTION),
    }));

    const dispatch = useThunkDispatch();
    const [showDisconnectFinchModal, setShowDisconnectFinchModal] = useState(false);

    const onDisconnectFinchYesClick = useCallback(async () => {
        await dispatch(disconnectFinch(team?.teamId ?? ''));
        setShowDisconnectFinchModal(false);
    }, [dispatch, team?.teamId]);

    const showDisconnectFinchButton =
        team?.finchIntegrationStatus !== FinchIntegrationStatuses.NotConfigured &&
        canDisconnectFinch;

    const actions = [
        {
            isVisible: showDisconnectFinchButton,
            onClick: () => setShowDisconnectFinchModal(true),
            text: 'Disconnect Finch',
        },
    ];

    return (
        <React.Fragment>
            <ActionButtons items={actions} />
            {showDisconnectFinchModal && (
                <ConfirmationModal
                    body="Are you sure you want to disconnect Finch?"
                    onNoClick={() => setShowDisconnectFinchModal(false)}
                    onYesClick={onDisconnectFinchYesClick}
                    showActivity={showActivity}
                    title="Disconnect Finch"
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(IntegrationsActionButtons);
