import { Card, CardContent, Grid, Paper, Stack, StackProps } from '@mui/material';
import { patchTeam } from 'actions/team/patchTeam';
import { HrsIntegrationProviders } from 'api/generated/enums';
import { GenerateHrsIntegrationKey, ViewHrsIntegrationKey } from 'api/generated/permissions';
import { IValueType } from 'components/EditableAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import PageHeader from 'components/PageHeader';
import Skeleton from 'components/Skeleton';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { startCase } from 'lodash';
import HrsIntegrationSection from 'pages/integrations/HrsIntegrationSection';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { onChange } from 'utilities/forms';
import { enumToNameValueArray, getEnumElementByIndex } from 'utilities/index';
import { string } from 'yup';
import IntegrationsActionButtons from './IntegrationsActionButtons';

const hrsIntegrationTypeFormatter = (x: string | undefined) => startCase(x);
const stackDirection: StackProps['direction'] = { sm: 'row', xs: 'column' };

const HRS_INTEGRATION_TYPE_OPTIONS = (enumToNameValueArray(HrsIntegrationProviders, {
    formatName: hrsIntegrationTypeFormatter,
    nameKey: 'label',
}) as unknown) as { label: string; value: HrsIntegrationProviders }[];

const IntegrationsPage = () => {
    const dispatch = useThunkDispatch();
    const { hasTeam, hasTeamIdUrlParam, team, teamId } = useTeamProps();
    const { canGenerateHrsIntegrationKey, canViewHrsIntegrationKey } = useSelector(
        (state: AppStore) => ({
            canGenerateHrsIntegrationKey: hasSomePermissions(state, GenerateHrsIntegrationKey),
            canViewHrsIntegrationKey: hasSomePermissions(state, ViewHrsIntegrationKey),
        })
    );

    const [hrsIntegrationProvider, setHrsIntegrationProvider] = useState<
        HrsIntegrationProviders | undefined
    >(team?.hrsIntegrationProvider);

    const save = useCallback(
        async (name, value) => {
            await dispatch(patchTeam(teamId, { [name]: value }, !hasTeamIdUrlParam, true));
        },
        [dispatch, hasTeamIdUrlParam, teamId]
    );

    const saveHrsIntegrationType = useCallback(
        async (name, value) => {
            await save(name, value);
        },
        [save]
    );

    const handleHrsIntegrationProviderChange = (value: number) => {
        const enumValue = getEnumElementByIndex(HrsIntegrationProviders, value);
        setHrsIntegrationProvider(enumValue);
    };

    useEffect(() => {
        if (team?.hrsIntegrationProvider !== undefined) {
            setHrsIntegrationProvider(team.hrsIntegrationProvider);
        }
    }, [team]);

    return (
        <React.Fragment>
            <Paper sx={{ mb: 3, p: 2 }}>
                <Stack
                    direction={stackDirection}
                    flexWrap="wrap"
                    gap={2}
                    justifyContent="space-between"
                >
                    <Stack
                        alignItems="center"
                        direction={stackDirection}
                        gap={2}
                        textAlign="center"
                    >
                        <PageHeader variant="h4">Integrations</PageHeader>
                    </Stack>
                    <Stack
                        alignItems="center"
                        direction={stackDirection}
                        gap={2}
                        textAlign="center"
                    >
                        <IntegrationsActionButtons team={team} />
                    </Stack>
                </Stack>
            </Paper>
            <Skeleton count={4} height="400px" isEnabled={!hasTeam}>
                <Grid columnSpacing={4} container rowSpacing={3}>
                    <Grid item xl={6} xs={12}>
                        <Card>
                            <CardContent>
                                {canViewHrsIntegrationKey && (
                                    <EditableSelectAttribute
                                        formatter={(x: IValueType) =>
                                            hrsIntegrationTypeFormatter(
                                                HrsIntegrationProviders[x as number]
                                            )
                                        }
                                        hrClassName="d-none"
                                        items={HRS_INTEGRATION_TYPE_OPTIONS}
                                        label="HRS Provider"
                                        name="hrsIntegrationProvider"
                                        onChange={onChange(handleHrsIntegrationProviderChange)}
                                        optionText="label"
                                        optionValue="value"
                                        readonly={!canGenerateHrsIntegrationKey}
                                        save={saveHrsIntegrationType}
                                        validationSchema={string()
                                            .label('HRS Integration Provider to Send')
                                            .required()}
                                        value={hrsIntegrationProvider}
                                    />
                                )}
                            </CardContent>
                        </Card>
                        <HrsIntegrationSection save={save} />
                    </Grid>
                </Grid>
            </Skeleton>
        </React.Fragment>
    );
};

export default hot(module)(IntegrationsPage);
