import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
    GET_PLAN_DETAILS_FOR_BENEFITS_SELECTION_ACTION,
    getPlanDetailsForBenefitsSelection,
} from 'actions/marketplacePlan/getPlanDetailsForBenefitsSelection';
import { getHouseholdEligibility } from 'actions/user/getHouseholdEligibility';
import { GroupLevels, PlanListing } from 'api/generated/enums';
import { IMarketplacePlanDto } from 'api/generated/models';
import MajorMedicalBenefitCostButton from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCostButton';
import ParagraphWithLineBreaks from 'components/ParagraphWithLineBreaks';
import ReimbursementMyCostTooltip from 'components/ReimbursementMyCostTooltip';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { setPlanForBenefitSelection } from 'pages/dashboard/medicalBenefitSelection/benefitSelectionActions';
import PlanCard from 'pages/shop/PlanCard';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { convertUrlsToHyperlinks, hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { doesMemberHaveAnyDisqualifyingEligibilities } from 'utilities/householdEligibility';

export enum ContributionStrategy {
    WageUp,
    Reimbursement,
}

export const PRESCRIPTIONS_PROVIDERS_DISCLAIMER_TEXT =
    'Whether specific doctors, hospitals, or prescriptions are covered changes often, so we cannot guarantee their coverage. As best practice, we recommend calling your doctor to see if they accept the plan, and checking with the plan carrier directly for prescription coverage.';

const OnOrOffExchangePathwayPlanDetails = ({
    contributionStrategy,
}: {
    contributionStrategy: ContributionStrategy;
}) => {
    const dispatch = useThunkDispatch();
    const { isInIchraPathway, user, userId, yearlyUserInfo } = useUserProps();
    const {
        householdEligibility,
        householdMembers,
        isLoading,
        isPlanLoaded,
        pathwayUser,
        plan,
    } = useSelector((state: AppStore) => ({
        benefitPlan: state.benefitSelectionState.plan,
        householdEligibility: state.householdEligibility,
        householdMembers: state.householdMembers,
        isLoading: hasApiActivity(state, GET_PLAN_DETAILS_FOR_BENEFITS_SELECTION_ACTION),
        isPlanLoaded: hasCompletedRequest(state, GET_PLAN_DETAILS_FOR_BENEFITS_SELECTION_ACTION),
        pathwayUser: state.pathwayUser,
        plan: { isMarketplace: true, ...state.marketplacePlans[0] },
    }));
    const [haveStoredPlan, setHaveStoredPlan] = useState(false);
    const currentContribution = user?.activeWageUps?.find(
        (x) => x.year === user?.activeDate?.getYear()
    );
    const isOffExchange = currentContribution?.benchmarkType === PlanListing.OffExchange;
    const isPrimaryCovered =
        yearlyUserInfo?.needsMajorMedicalCoverage &&
        !doesMemberHaveAnyDisqualifyingEligibilities(
            userId,
            householdEligibility,
            isInIchraPathway
        );
    const individualOnly = yearlyUserInfo?.majorMedicalCoverageLevel === GroupLevels.EE;
    const householdMembersCovered = useMemo(
        () =>
            [...householdMembers]
                .filter(
                    (x) =>
                        x.needsCoverage &&
                        !doesMemberHaveAnyDisqualifyingEligibilities(
                            x.householdMemberId,
                            householdEligibility,
                            isInIchraPathway
                        )
                )
                .map((x) => x.householdMemberId),
        [householdEligibility, householdMembers, isInIchraPathway]
    );
    useEffect(() => {
        dispatch(getHouseholdEligibility(userId, user?.activeDate?.getYear() as number));
        if (pathwayUser?.planId && user?.activeDate && !isPlanLoaded) {
            dispatch(
                getPlanDetailsForBenefitsSelection(
                    user.userId,
                    [pathwayUser.planId],
                    user.activeDate.getYear(),
                    isOffExchange,
                    false
                )
            );
        }
    }, [dispatch, isOffExchange, isPlanLoaded, pathwayUser, user, userId]);
    useEffect(() => {
        if (isPlanLoaded && !haveStoredPlan) {
            dispatch(setPlanForBenefitSelection(plan as IMarketplacePlanDto));
            setHaveStoredPlan(true);
        }
    }, [dispatch, haveStoredPlan, isPlanLoaded, plan]);
    const memberIchraCost = plan.majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost;
    const myCost =
        contributionStrategy === ContributionStrategy.WageUp ? (
            <MajorMedicalBenefitCostButton majorMedicalBenefitCost={plan.majorMedicalBenefitCost} />
        ) : (
            <Typography variant="h3">
                Your Major Medical Cost:{' '}
                {formatCurrency(memberIchraCost, { preserveDecimal: true })} per month
                <ReimbursementMyCostTooltip
                    memberCost={memberIchraCost}
                    planCost={plan.premium}
                    reimbursement={currentContribution?.reimbursement}
                />
            </Typography>
        );
    return (
        <Skeleton count={1} height="300px" isEnabled={isLoading}>
            <Col>
                <Row className="justify-content-center mb-2">
                    Based on your household information, this pathway would be a great fit for you.
                </Row>
                {isPlanLoaded && (
                    <React.Fragment>
                        <div>
                            {hasValue(pathwayUser?.displayNameOverride) ? (
                                <Typography textAlign="center" variant="h3">
                                    {pathwayUser.displayNameOverride}
                                </Typography>
                            ) : (
                                <PlanCard
                                    hideCost
                                    hideSelectPlan
                                    householdMembersCovered={householdMembersCovered}
                                    individualOnly={individualOnly}
                                    isPrimaryCovered={isPrimaryCovered}
                                    plan={plan}
                                />
                            )}
                        </div>
                        <Stack justifyContent="center" marginBottom={2} spacing={2}>
                            <Typography
                                fontSize={11}
                                fontStyle="italic"
                                fontWeight="light"
                                variant="caption"
                            >
                                {PRESCRIPTIONS_PROVIDERS_DISCLAIMER_TEXT}
                            </Typography>
                            {hasValue(pathwayUser?.extraBenefitText) && (
                                <Row className="justify-content-center text-start mx-3">
                                    <ParagraphWithLineBreaks
                                        dangerouslySetInnerHTML={{
                                            __html: convertUrlsToHyperlinks(
                                                pathwayUser.extraBenefitText
                                            ),
                                        }}
                                    />
                                </Row>
                            )}
                            {myCost}
                        </Stack>
                    </React.Fragment>
                )}
            </Col>
        </Skeleton>
    );
};

export default hot(module)(OnOrOffExchangePathwayPlanDetails);
