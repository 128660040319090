import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { ADD_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/addPathwayBlueprint';
import { EDIT_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/editPathwayBlueprint';
import { GET_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS_ACTION } from 'actions/pathwayBlueprint/getPlansForNextYearAsync';
import { IPathwayMarketplacePlanMigration } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import PathwayMarketplacePlanMigrationInputs, {
    PATHWAY_TYPE_NAMES,
} from 'pages/pathwayBlueprints/PathwayMarketplacePlanMigrationInputs';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';

const PathwayMigrationsContent = ({
    migrations,
}: {
    migrations: IPathwayMarketplacePlanMigration[];
}) => {
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            ADD_PATHWAY_BLUEPRINT_ACTION,
            EDIT_PATHWAY_BLUEPRINT_ACTION,
            GET_PATHWAY_BLUEPRINT_ACTION,
            GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS_ACTION
        ),
    }));
    const ichraClassPlanMigrations = migrations.filter((pmpm) => hasValue(pmpm.ichraClass));
    const pathwayPlanMigrations = migrations.filter((pmpm) => hasValue(pmpm.pathway));
    const recommendedPlanMigrations = migrations.filter((pmpm) =>
        hasValue(pmpm.pathwayRecommendedPlan)
    );
    const ichraClassPlanMigrationsContent = ichraClassPlanMigrations.map((x, index) => (
        <PathwayMarketplacePlanMigrationInputs
            index={index}
            key={`ichraClassPlanMigration-${x.ichraClass?.id}`}
            {...x}
        />
    ));

    const pathwayPlanMigrationsContent = pathwayPlanMigrations.map((x, index) => {
        const recommendedPlansForPathway = recommendedPlanMigrations.filter((rpm) =>
            x.pathway?.pathwayRecommendedPlans?.some(
                (prp) => prp.id === rpm.pathwayRecommendedPlan?.id
            )
        );

        return (
            <React.Fragment key={`pathwayPlanMigration-${x.pathway?.id}`}>
                {arrayHasValue(recommendedPlansForPathway) && (
                    <Typography marginTop={4} variant="h4">
                        {x.pathway?.name}
                        {' - '}
                        <span style={{ fontStyle: 'italic' }}>
                            {PATHWAY_TYPE_NAMES?.[x.pathway?.pathwayTypeId as number]}
                        </span>
                    </Typography>
                )}
                <PathwayMarketplacePlanMigrationInputs
                    index={index}
                    {...x}
                    hasMultipleRecommendedPlans={arrayHasValue(recommendedPlansForPathway)}
                />
                {recommendedPlansForPathway?.map((y, recPlanIndex) => (
                    <PathwayMarketplacePlanMigrationInputs
                        hasMultipleRecommendedPlans={arrayHasValue(recommendedPlansForPathway)}
                        index={recPlanIndex + 1}
                        {...y}
                        key={`recommendedPlanMigration-${x.pathwayRecommendedPlan?.id}`}
                        pathway={x.pathway}
                    />
                ))}
            </React.Fragment>
        );
    });

    return (
        <Stack>
            <Skeleton
                isEnabled={isLoading}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 2,
                            height: 260,
                            sx: { mb: 3, mt: 2 },
                            width: '100%',
                        },
                    ],
                ]}
            >
                {arrayHasValue(ichraClassPlanMigrationsContent) && (
                    <React.Fragment>
                        <Divider sx={{ my: 2 }} />
                        <Typography sx={{ mb: 0 }} variant="h3">
                            ICHRA Classes
                        </Typography>
                        {ichraClassPlanMigrationsContent}
                    </React.Fragment>
                )}
                {arrayHasValue(pathwayPlanMigrationsContent) && (
                    <React.Fragment>
                        <Divider sx={{ mb: 2, mt: 4 }} />
                        <Typography sx={{ mb: 0 }} variant="h3">
                            Pathways
                        </Typography>
                        {pathwayPlanMigrationsContent}
                    </React.Fragment>
                )}
            </Skeleton>
        </Stack>
    );
};

export default hot(module)(PathwayMigrationsContent);
