import Grid from '@mui/material/Grid';
import { AgeBandSizes, TopLineReimbursementStrategies } from 'api/generated/enums';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import CalculatedAgeBandStrategiesContent from 'components/IchraClassModal/CalculatedAgeBandStrategiesContent';
import ImportAgeBandedTableContent from 'components/IchraClassModal/ImportAgeBandedTableContent';
import Select from 'components/Select';
import TextField from 'components/TextField';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { IchraClassFormContext } from 'pages/pathwayBlueprint/ichraClasses/IchraClassModal';
import { getAdditionalDependentKickerHelperText } from 'pages/pathwayBlueprint/PathwayModal';
import { clearPathwayInputs } from 'pages/pathwayBlueprint/pathwayModalActions';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';
import { enumToNameValueArray, hasValue } from 'utilities/index';
import { array, object, string } from 'yup';

const ICHRA_RATIO_MAX = 3;
const ICHRA_DEPENDENT_KICKER_THRESHOLD_MAX = 10;
const ICHRA_CLASS_NAME_MAX = 255;
const topLineReimbursementStrategyArray = enumToNameValueArray(TopLineReimbursementStrategies, {
    nameMap: {
        CoverageLevelPercentage: 'Coverage Level Percentage',
        Custom: 'Custom',
        SplitPrimaryHouseholdPercentage: 'Split Primary Household Percentage',
    },
});

const isCoverageLevelPercentage = [
    'topLineReimbursementStrategyId',
    {
        is: (x: number | string) =>
            hasValue(x) && Number(x) === TopLineReimbursementStrategies.CoverageLevelPercentage,
        then: string().required(),
    },
] as const;
const isSplitPrimaryHouseholdPercentage = [
    'topLineReimbursementStrategyId',
    {
        is: (x: number | string) =>
            hasValue(x) &&
            Number(x) === TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
        then: string().required(),
    },
] as const;
const isImportedStrategy = [
    'topLineReimbursementStrategyId',
    {
        is: (x: number | string) =>
            hasValue(x) && Number(x) === TopLineReimbursementStrategies.Import,
        then: array().required(),
    },
] as const;
const isPercentageCostStrategy = [
    'topLineReimbursementStrategyId',
    {
        is: (x: number | string) =>
            hasValue(x) &&
            [
                TopLineReimbursementStrategies.CoverageLevelPercentage,
                TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
            ].includes(Number(x)),
        then: string().required(),
    },
] as const;
const isCalculatedAgeBandStrategy = [
    'topLineReimbursementStrategyId',
    {
        is: (x: number | string) =>
            hasValue(x) &&
            [
                TopLineReimbursementStrategies.CoverageLevelPercentage,
                TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
                TopLineReimbursementStrategies.Custom,
            ].includes(Number(x)),
        then: string().required(),
    },
] as const;

export const ichraClassSchema = (
    needsToApplyStrategy: boolean,
    topLineReimbursementStrategy: TopLineReimbursementStrategies
) =>
    object({
        additionalDependentKicker: string()
            .min(0)
            .label('Additional Dependent Kicker'),
        ageBandSizeId: string()
            .when(...isCalculatedAgeBandStrategy)
            .label('Age Band Size'),
        county: string()
            .trim()
            .when(...isPercentageCostStrategy)
            .label('County'),
        dependentKickerCoveragePercent: string()
            .min(0)
            .label('Dependent Kicker Coverage Percent'),
        dependentKickerThreshold: string()
            .max(ICHRA_DEPENDENT_KICKER_THRESHOLD_MAX)
            .min(0)
            .required()
            .label('Dependent Kicker Threshold'),
        ec64: string()
            .isRequiredWhen(
                !needsToApplyStrategy &&
                    topLineReimbursementStrategy !== TopLineReimbursementStrategies.Import
            )
            .min(1)
            .label('64+ EC'),
        ecCoveragePercent: string()
            .when(...isCoverageLevelPercentage)
            .min(0)
            .label('EC Coverage Percentage'),
        ecRatio: string()
            .when(...isCalculatedAgeBandStrategy)
            .min(1)
            .max(ICHRA_RATIO_MAX)
            .label('EC Ratio'),
        ee64: string()
            .isRequiredWhen(
                !needsToApplyStrategy &&
                    topLineReimbursementStrategy !== TopLineReimbursementStrategies.Import
            )
            .min(1)
            .label('64+ EE'),
        eeCoveragePercent: string()
            .when(...isPercentageCostStrategy)
            .min(0)
            .label('EE Coverage Percentage'),
        eeRatio: string()
            .when(...isCalculatedAgeBandStrategy)
            .min(1)
            .max(ICHRA_RATIO_MAX)
            .label('EE Ratio'),
        ef64: string()
            .isRequiredWhen(
                !needsToApplyStrategy &&
                    topLineReimbursementStrategy !== TopLineReimbursementStrategies.Import
            )
            .min(1)
            .label('64+ EF'),
        efCoveragePercent: string()
            .when(...isCoverageLevelPercentage)
            .min(0)
            .label('EF Coverage Percentage'),
        efRatio: string()
            .when(...isCalculatedAgeBandStrategy)
            .min(1)
            .max(ICHRA_RATIO_MAX)
            .label('EF Ratio'),
        es64: string()
            .isRequiredWhen(
                !needsToApplyStrategy &&
                    topLineReimbursementStrategy !== TopLineReimbursementStrategies.Import
            )
            .min(1)
            .label('64+ ES'),
        esCoveragePercent: string()
            .when(...isCoverageLevelPercentage)
            .min(0)
            .label('ES Coverage Percentage'),
        esRatio: string()
            .when(...isCalculatedAgeBandStrategy)
            .min(1)
            .max(ICHRA_RATIO_MAX)
            .label('ES Ratio'),
        ichraBenchmarkType: string()
            .when(...isPercentageCostStrategy)
            .label('ICHRA Benchmark Type'),
        importedAgeBandedTable: array()
            .when(...isImportedStrategy)
            .label('Imported Age-Banded Table'),
        marketplaceCarrier: string()
            .when(...isPercentageCostStrategy)
            .label('Benchmark Carrier'),
        marketplacePlanId: string()
            .when(...isPercentageCostStrategy)
            .label('Benchmark Plan'),
        name: string()
            .trim()
            .required()
            .max(ICHRA_CLASS_NAME_MAX)
            .label('Class Name'),
        otherHouseholdMemberCoveragePercentage: string()
            .when(...isSplitPrimaryHouseholdPercentage)
            .min(0)
            .label('Other Coverage Household Percentage'),
        topLineReimbursementStrategyId: string()
            .required()
            .label('Top-Line Reimbursement Strategy'),
        zipCode: string()
            .trim()
            .when(...isPercentageCostStrategy)
            .label('Zip Code'),
    });

const IchraClassForm = () => {
    const { errors, ichraClass, setIchraClass } = useContext(IchraClassFormContext);
    const content =
        ichraClass?.topLineReimbursementStrategyId === TopLineReimbursementStrategies.Import ? (
            <ImportAgeBandedTableContent />
        ) : (
            <CalculatedAgeBandStrategiesContent />
        );
    const dispatch = useThunkDispatch();

    const onReimbursementStrategyChange = (
        topLineReimbursementStrategy: TopLineReimbursementStrategies
    ) => {
        const previousTopLineReimbursementStrategy = ichraClass.topLineReimbursementStrategyId;
        let classToSet = {
            ...ichraClass,
            topLineReimbursementStrategyId: topLineReimbursementStrategy,
        };
        if (
            [TopLineReimbursementStrategies.Custom, TopLineReimbursementStrategies.Import].contains(
                topLineReimbursementStrategy
            )
        ) {
            classToSet = {
                ...classToSet,
                benchmarkCarrier: undefined,
                benchmarkPlanId: undefined,
                benchmarkPlanType: undefined,
                county: undefined,
                dependentKickerCoveragePercentage: undefined,
                ecCoveragePercentage: undefined,
                eeCoveragePercentage: undefined,
                efCoveragePercentage: undefined,
                esCoveragePercentage: undefined,
                otherHouseholdMemberCoveragePercentage: undefined,
                state: undefined,
                zipCode: undefined,
            };
            dispatch(clearPathwayInputs());

            if (topLineReimbursementStrategy === TopLineReimbursementStrategies.Import) {
                classToSet = {
                    ...classToSet,
                    ecRatio: undefined,
                    eeRatio: undefined,
                    efRatio: undefined,
                    esRatio: undefined,
                };
            }
        } else if (
            topLineReimbursementStrategy === TopLineReimbursementStrategies.CoverageLevelPercentage
        ) {
            classToSet = {
                ...classToSet,
                otherHouseholdMemberCoveragePercentage: undefined,
            };
        } else if (
            topLineReimbursementStrategy ===
            TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage
        ) {
            classToSet = {
                ...classToSet,
                ecCoveragePercentage: undefined,
                efCoveragePercentage: undefined,
                esCoveragePercentage: undefined,
            };
        }
        if (
            previousTopLineReimbursementStrategy === TopLineReimbursementStrategies.Import &&
            topLineReimbursementStrategy !== TopLineReimbursementStrategies.Import
        ) {
            classToSet = {
                ...classToSet,
                ageBandSizeId: AgeBandSizes.Five,
                ecRatio: 3,
                eeRatio: 3,
                efRatio: 3,
                esRatio: 3,
                importedAgeBandedTable: undefined,
            };
        }
        setIchraClass(classToSet);
    };
    return (
        <Form>
            <Grid columnSpacing={2} container item marginTop={2} rowGap={2} xs={12}>
                <Grid item xs={12}>
                    <TextField
                        errors={errors?.name}
                        label="Class Name"
                        name="className"
                        onChange={(e) => setIchraClass({ ...ichraClass, name: e.target.value })}
                        value={ichraClass?.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        errors={errors?.topLineReimbursementStrategyId}
                        items={topLineReimbursementStrategyArray}
                        label="Top-Line Reimbursement Strategy"
                        name="topLineReimbursementStrategy"
                        onChange={(e) => onReimbursementStrategyChange(Number(e.target.value))}
                        optionText="name"
                        optionValue="value"
                        value={ichraClass?.topLineReimbursementStrategyId}
                    />
                </Grid>
                {content}
                <Grid item md={6} xs={12}>
                    <TextField
                        errors={errors?.dependentKickerThreshold}
                        helperText="This sets the number of dependents a household can have before the kicker will be added for each additional dependent."
                        label="Dependent Kicker Threshold"
                        name="dependentKickerThreshold"
                        onChange={(e) =>
                            setIchraClass({
                                ...ichraClass,
                                dependentKickerThreshold: Number(e.target.value),
                            })
                        }
                        value={
                            isNaN(ichraClass.dependentKickerThreshold)
                                ? 0
                                : ichraClass.dependentKickerThreshold
                        }
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <CurrencyTextField
                        disabled={[
                            TopLineReimbursementStrategies.CoverageLevelPercentage,
                            TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
                        ].includes(ichraClass?.topLineReimbursementStrategyId)}
                        errors={errors?.additionalDependentKicker}
                        helperText={getAdditionalDependentKickerHelperText(
                            ichraClass?.dependentKickerThreshold
                        )}
                        isOptional
                        label="Additional Dependent Kicker"
                        name="additionalDependentKicker"
                        onChange={(e) =>
                            setIchraClass({
                                ...ichraClass,
                                additionalDependentKicker: Number(e.target.value),
                            })
                        }
                        value={ichraClass?.additionalDependentKicker}
                    />
                </Grid>
            </Grid>
        </Form>
    );
};

export default hot(module)(IchraClassForm);
