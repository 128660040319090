import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { clearApiActivityClearShoppingPlanFetches } from 'actions/clear';
import {
    PROCESS_COVERAGE_LEVEL_FOR_CONTRIBUTION_ACTION,
    processCoverageLevelForContribution,
} from 'actions/householdMember/processCoverageLevelForContribution';
import { applyShoppingCoverageChanges } from 'actions/householdShoppingCoverage/applyShoppingCoverageChanges';
import { setInitialContribution } from 'actions/householdShoppingCoverage/setInitialContribution';
import { getHousehold } from 'actions/user/getHousehold';
import { getHouseholdEligibility } from 'actions/user/getHouseholdEligibility';
import { HouseholdEligibilityStatus } from 'api/generated/enums';
import Button from 'components/Button';
import CalendlyModalLink from 'components/calendly/CalendlyModalLink';
import Checkbox from 'components/Checkbox';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { ShoppingContext } from 'pages/shop/shopPageUtilities';
import { handleToolTip } from 'pages/shop/shoppingMemberSelectionUtilities';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatCurrency } from 'utilities/format';
import { hasValue } from 'utilities/index';

type ChangeHouseholdCoverageModalProps = {
    isIchraFlow: boolean | undefined;
    isUnclaimedAllowed: boolean;
    onClose: () => void;
};

const ChangeHouseholdCoverageModal = ({
    isIchraFlow,
    isUnclaimedAllowed,
    onClose,
}: ChangeHouseholdCoverageModalProps) => {
    const dispatch = useThunkDispatch();
    const { activeWageUp, isCurrent, isInIchraPathway, user, userId } = useUserProps();
    const { setHasFetchedPlans, shoppingMemberSelections } = useContext(ShoppingContext);
    const { closeModal } = useModalState({
        key: 'calendly',
    });
    const { householdShoppingCoverage, isLoading } = useSelector((state: AppStore) => ({
        householdMembers: state.householdMembers,
        householdShoppingCoverage: state.householdShoppingCoverage,
        isLoading: hasApiActivity(state, PROCESS_COVERAGE_LEVEL_FOR_CONTRIBUTION_ACTION),
    }));
    const [selectedHhms, setSelectedHhms] = useState<string[]>([]);
    const numSelectedMembers = selectedHhms.length;
    const year = user?.activeDate?.getYear() ?? 0;
    const contributionAmount = formatCurrency(householdShoppingCoverage?.contribution, {
        preserveDecimal: true,
    });

    const handleRecalc = async (isSelected: boolean, memberId: string, isPrimary: boolean) => {
        await dispatch(
            processCoverageLevelForContribution(memberId, year, isSelected, !isIchraFlow, isPrimary)
        );
        if (!isInIchraPathway) {
            await dispatch(getHousehold(userId, isCurrent));
            await dispatch(getHouseholdEligibility(userId, year));
        }
    };

    const handleSelect = (memberId: string, isPrimary: boolean) => {
        let hhmsToAdd = [...selectedHhms];
        const isSelected = hhmsToAdd.includes(memberId);
        if (isSelected) {
            hhmsToAdd = hhmsToAdd.filter((hhm) => hhm !== memberId);
        } else {
            hhmsToAdd.push(memberId);
        }
        setSelectedHhms(hhmsToAdd);

        handleRecalc(!isSelected, memberId, isPrimary);
    };

    const applyChangesAndClose = useCallback(() => {
        dispatch(applyShoppingCoverageChanges(householdShoppingCoverage?.householdMembersChanged));
        dispatch(clearApiActivityClearShoppingPlanFetches());
        onClose();
    }, [dispatch, householdShoppingCoverage?.householdMembersChanged, onClose]);

    useEffect(() => {
        const selected =
            shoppingMemberSelections?.filter((sms) => sms.isSelected)?.map((sms) => sms.entityId) ??
            [];
        setSelectedHhms(selected);
    }, [shoppingMemberSelections]);

    useEffect(() => {
        const activeContribution =
            (hasValue(activeWageUp?.reimbursement)
                ? activeWageUp?.reimbursement
                : activeWageUp?.wageUp) ?? 0;
        dispatch(setInitialContribution(activeContribution));
    }, [activeWageUp?.reimbursement, activeWageUp?.wageUp, dispatch]);

    useEffect(
        () => () => {
            setHasFetchedPlans?.(false);
        },
        [setHasFetchedPlans]
    );

    return (
        <Dialog fullWidth maxWidth="sm" open>
            <DialogTitle>
                <Typography textAlign="center" variant="h3">
                    Change Who Needs Coverage
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column" gap={2}>
                    <Typography variant="body1">
                        Check or uncheck household members to change who you are seeking coverage
                        for. This will update who you are shopping for and may change your{' '}
                        {isInIchraPathway ? 'reimbursement' : 'Wage+'} amount.
                    </Typography>
                    {!isIchraFlow && (
                        <Typography variant="body1">
                            Making changes will clear your current plan selections.
                        </Typography>
                    )}
                    <Grid container direction="row" gap={2} item marginX="auto">
                        {shoppingMemberSelections?.map((sms, index) => (
                            <Tooltip
                                data-cy={`member-tooltip-${index}`}
                                key={sms.firstName}
                                title={handleToolTip(
                                    true,
                                    sms,
                                    isInIchraPathway,
                                    isUnclaimedAllowed,
                                    numSelectedMembers
                                )}
                            >
                                <Checkbox
                                    checked={selectedHhms?.includes(sms.entityId)}
                                    data-cy={`member-${index}`}
                                    disabled={
                                        sms.isDisabled ||
                                        isLoading ||
                                        (sms.isSelected && numSelectedMembers === 1)
                                    }
                                    label={sms.firstName}
                                    name="memberName"
                                    onChange={() => handleSelect(sms.entityId, sms.isPrimary)}
                                />
                            </Tooltip>
                        ))}
                    </Grid>
                    <Divider />
                    <Stack alignItems="center" direction="row" justifyContent="center" spacing={1}>
                        <Typography variant="h4">
                            {isInIchraPathway ? 'Reimbursement' : 'Wage+'} Amount:
                        </Typography>
                        <Skeleton height={24} isEnabled={isLoading} width={90}>
                            <Typography variant="h4">{contributionAmount}</Typography>
                        </Skeleton>
                    </Stack>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Stack direction="column" gap={3} width="100%">
                    <Grid container justifyContent="center" xs={12}>
                        <Button
                            data-cy="apply-household-coverage-change-button"
                            isLoading={isLoading}
                            onClick={applyChangesAndClose}
                            variant="contained"
                        >
                            Continue
                        </Button>
                    </Grid>
                    {!isInIchraPathway &&
                        shoppingMemberSelections?.some((sms) =>
                            [
                                HouseholdEligibilityStatus.Medicaid,
                                HouseholdEligibilityStatus.CHIP,
                            ].includes(
                                sms.memberEligibility?.eligibilityStatus ??
                                    HouseholdEligibilityStatus.None
                            )
                        ) && (
                            <Typography paddingX={4} textAlign="center" variant="body2">
                                Based on your income with this Wage+ amount, you may be eligible for
                                Medicaid/CHIP. Please{' '}
                                <CalendlyModalLink
                                    onAdvisementScheduled={closeModal}
                                    text="schedule an advisement"
                                />{' '}
                                with one our our licensed advisors to talk through your options.
                            </Typography>
                        )}
                    <Typography alignSelf="center" marginBottom={1} variant="body2">
                        To add someone to your household reach out to{' '}
                        <a href="care@remodelhealth.com">care@remodelhealth.com</a>
                    </Typography>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(ChangeHouseholdCoverageModal);
