import { AvTimer, CancelOutlined, CheckCircleOutline, InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { getFinchProviders } from 'actions/finch/getFinchProviders';
import { getFinchStatus } from 'actions/finch/getFinchStatus';
import { FinchEmploymentSubtypes, FinchIntegrationStatuses, Products } from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import { IValueType } from 'components/EditableAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import ProfileAttribute from 'components/ProfileAttribute';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatDateAtTime } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { enumToNameValueArray, flagsToValuesArray } from 'utilities/index';
import { array, number, string } from 'yup';

export const finchEmploymentSubtypeIdNames = {
    [FinchEmploymentSubtypes.FullTime]: 'Full-time',
    [FinchEmploymentSubtypes.PartTime]: 'Part-time',
    [FinchEmploymentSubtypes.Intern]: 'Intern',
    [FinchEmploymentSubtypes.Temp]: 'Temporary',
    [FinchEmploymentSubtypes.Seasonal]: 'Seasonal',
    [FinchEmploymentSubtypes.IndividualContract]: 'Individual Contract',
};

export const FINCH_EMPLOYMENT_SUBTYPE_ITEMS = enumToNameValueArray(FinchEmploymentSubtypes, {
    formatName: startCase,
    nameKey: 'text',
    nameMap: {
        FullTime: finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.FullTime],
        IndividualContract:
            finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.IndividualContract],
        Intern: finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.Intern],
        PartTime: finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.PartTime],
        Seasonal: finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.Seasonal],
        Temp: finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.Temp],
    },
});

const FinchIntegrationSection = ({ save }: { save: ISaveEditableTextField<ITeam> }) => {
    const dispatch = useThunkDispatch();
    const { team } = useTeamProps();
    const { finchProviders, teamFinchStatus } = useSelector((state: AppStore) => ({
        finchProviders: state.finch.providers,
        teamFinchStatus: state.teamFinch.status,
    }));

    const [finchProviderId, setFinchProviderId] = useState<string | undefined>(
        team?.finchProviderId
    );
    const [supportedFinchEmploymentSubtypes, setSupportedFinchEmploymentSubtypes] = useState<
        FinchEmploymentSubtypes[]
    >(
        flagsToValuesArray(
            team?.supportedFinchEmploymentSubtypes,
            finchEmploymentSubtypeIdNames,
            'value'
        ).map((x) => x['value'] as FinchEmploymentSubtypes)
    );
    const saveFinchProviderId = useCallback(
        async (name, value) => {
            await save(name, value);
        },
        [save]
    );

    const saveSupportedFinchEmploymentSubtypes: ISaveEditableTextField<ITeam> = async (
        name,
        valueAsArray
    ) => {
        const value = (valueAsArray as string[]).reduce(
            (previousValue, currentValue) => Number(currentValue) | previousValue,
            0
        );
        await save(name, value);
    };

    useEffect(() => {
        if (finchProviders === null || (finchProviders && finchProviders.length === 0)) {
            dispatch(getFinchProviders());
        }
        if (
            !teamFinchStatus &&
            team?.finchIntegrationStatus === FinchIntegrationStatuses.Connected
        ) {
            dispatch(getFinchStatus(team.teamId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <React.Fragment>
            <ProfileAttribute label="Status">
                {startCase(
                    FinchIntegrationStatuses[
                        team?.finchIntegrationStatus ?? FinchIntegrationStatuses.NotConfigured
                    ]
                )}
            </ProfileAttribute>
            {finchProviders && (
                <EditableSelectAttribute
                    formatter={(x: IValueType) =>
                        finchProviders.find((p) => p.id === x)?.displayName ?? ''
                    }
                    items={finchProviders}
                    label="Provider"
                    name="finchProviderId"
                    onChange={onChange(setFinchProviderId)}
                    optionText="displayName"
                    optionValue="id"
                    save={saveFinchProviderId}
                    validationSchema={string().label('Provider')}
                    value={finchProviderId}
                />
            )}
            {teamFinchStatus && (
                <React.Fragment>
                    <ProfileAttribute label="Products">
                        {teamFinchStatus.introspect?.products?.map((product, index) => (
                            <div key={`${product}-${index}`}>{startCase(Products[product])}</div>
                        ))}
                    </ProfileAttribute>
                    <ProfileAttribute label="Authentication Methods">
                        {teamFinchStatus.introspect?.authenticationMethods?.map(
                            (authenticationMethod, index) => {
                                const status = authenticationMethod.connectionStatus?.status;

                                let IconComponent = <React.Fragment />;

                                if (status === 'connected') {
                                    IconComponent = <CheckCircleOutline color="success" />;
                                } else if (status === 'pending' || status === 'processing') {
                                    IconComponent = <AvTimer color="secondary" />;
                                } else if (
                                    status === 'error_no_account_setup' ||
                                    status === 'error_permissions'
                                ) {
                                    IconComponent = <CancelOutlined color="warning" />;
                                } else if (status === 'reauth') {
                                    IconComponent = <InfoOutlined color="error" />;
                                }

                                return (
                                    <React.Fragment key={`${authenticationMethod.type}-${index}`}>
                                        <div>
                                            {startCase(authenticationMethod.type)}:{' '}
                                            {startCase(
                                                authenticationMethod.connectionStatus?.status
                                            )}
                                            {authenticationMethod.connectionStatus?.message && (
                                                <Tooltip
                                                    className="ml-1"
                                                    title={
                                                        authenticationMethod.connectionStatus
                                                            .message
                                                    }
                                                >
                                                    {IconComponent}
                                                </Tooltip>
                                            )}
                                        </div>
                                    </React.Fragment>
                                );
                            }
                        )}
                    </ProfileAttribute>
                    <ProfileAttribute label="Recent Automated Jobs">
                        {teamFinchStatus.jobs?.map((job, index) =>
                            teamFinchStatus.jobs ? (
                                <React.Fragment key={job.jobId}>
                                    <div>
                                        <div>
                                            <strong>Type:</strong> {startCase(job.type)}
                                        </div>
                                        <div>
                                            <strong>Status:</strong> {startCase(job.status)}
                                        </div>
                                        {job.scheduledAt && (
                                            <div>
                                                <strong>Scheduled At:</strong>{' '}
                                                {formatDateAtTime(job.scheduledAt)}
                                            </div>
                                        )}
                                        {job.startedAt && (
                                            <div>
                                                <strong>Started At:</strong>{' '}
                                                {formatDateAtTime(job.startedAt)}
                                            </div>
                                        )}
                                        {job.completedAt && (
                                            <div>
                                                <strong>Completed At:</strong>{' '}
                                                {formatDateAtTime(job.completedAt)}
                                            </div>
                                        )}
                                    </div>
                                    {index < teamFinchStatus.jobs.length - 1 && <hr />}
                                </React.Fragment>
                            ) : (
                                <React.Fragment key={job.jobId} />
                            )
                        )}
                    </ProfileAttribute>
                </React.Fragment>
            )}
            <EditableSelectAttribute
                formatter={() =>
                    supportedFinchEmploymentSubtypes
                        .map(
                            (x) =>
                                finchEmploymentSubtypeIdNames[Number(x) as FinchEmploymentSubtypes]
                        )
                        .joinSerialComma()
                }
                infoTooltip="Employees with the selected employment type(s) will be processed by the HRIS integration"
                items={FINCH_EMPLOYMENT_SUBTYPE_ITEMS}
                label="Employee Filter Criteria"
                name="supportedFinchEmploymentSubtypes"
                onChange={onChange(setSupportedFinchEmploymentSubtypes)}
                optionText="text"
                optionValue="value"
                save={saveSupportedFinchEmploymentSubtypes}
                SelectProps={{
                    multiple: true,
                    native: false,
                }}
                validationSchema={array(number()).label('Employment Subtype')}
                value={(supportedFinchEmploymentSubtypes as unknown) as string[]}
            />
        </React.Fragment>
    );
};

export default hot(module)(FinchIntegrationSection);
